<template>
  <div>
    <v-toolbar v-if="!hideNavBar">
      <div class="d-flex">
        <v-icon
          large
          class="hidden-lg-and-up mr-2"
          v-if="isAuthenticated"
          @click.stop="drawer = !drawer"
        >
          mdi-menu
        </v-icon>
        <router-link to="/" class="btn_home">
          <img
            v-if="
              user?.company?.domain?.static &&
                user?.company?.domain?.static?.type === 'image'
            "
            class=""
            :src="getImageUri()"
            alt=""
            width="130"
            style="max-height: 37px; height: auto;"
          />
          <img
            v-else
            class=""
            src="@/assets/logo.png"
            alt=""
            width="130"
            style="max-height: 37px; height: auto;"
          />

          <div class="d-flex">
            <span class="logo-title ml-2">Plataforma</span>
            <span class="logo-version mr-1">2.0</span>
          </div>
        </router-link>
      </div>

      <v-toolbar-items class="hidden-md-and-down" v-if="isAuthenticated">
        <template v-for="item in selected_menu">
          <v-btn :to="item.link" :key="item.title" v-if="!item.sub_items">
            <b-icon :icon="item.icon" aria-hidden="true"></b-icon>
            <template v-if="isShowBadgeTopNav(item, globalState)">
              <v-badge color="pink" inline dot>
                {{ item.title }}
              </v-badge>
            </template>
            <template v-else>
              {{ item.title }}
            </template>
          </v-btn>

          <v-menu bottom left offset-y v-if="item.sub_items" :key="item.title">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <b-icon :icon="item.icon" aria-hidden="true"></b-icon>&nbsp;
                <template v-if="isShowBadgeTopNav(item, globalState)">
                  <v-badge color="pink" inline dot>
                    {{ item.title }}
                  </v-badge>
                </template>
                <template v-else>
                  {{ item.title }}
                </template>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="sub_item in item.sub_items"
                :key="sub_item.title"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link :to="sub_item.link">
                      <template v-if="isShowBadgeSubNav(sub_item, globalState)">
                        <v-badge color="pink" inline dot>
                          {{ sub_item.title }}
                        </v-badge>
                      </template>
                      <template v-else>{{ sub_item.title }}</template>
                    </router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <div class="d-flex justify-space-between align-center" style="gap: 4px;">
        <v-btn v-if="isAuthenticated && manual?.static && !isBrand" @click="showDialogPdfFile()">
          <span style="color: black">Manual</span>
        </v-btn>

        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <country-flag
                v-if="locale === 'es'"
                country="es"
                size="normal"
                class="custom-flag"
              />
              <country-flag
                v-if="locale === 'en'"
                country="us"
                size="normal"
                class="custom-flag"
              />
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="switchLocale('es')" :key="'es'">
              <v-list-item-title>
                <country-flag country="es" size="normal" />
                <span>{{ $t("navbar.spainish") }}</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="switchLocale('en')" :key="'en'">
              <v-list-item-title>
                <country-flag country="us" size="normal" />
                <span>{{ $t("navbar.english") }}</span></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          :close-on-content-click="false"
          bottom
          left
          offset-y
          v-if="isAuthenticated"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="hidden-md-and-down">
              <b-icon
                icon="person-fill"
                class="mr-2"
                style="color: black"
                aria-hidden="true"
              ></b-icon>
              <span style="color: black">{{ user.username }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-title>
                <b-icon icon="door-open" aria-hidden="true"></b-icon>
                {{ $t("navbar.Logout") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn class="hidden-lg-and-up" v-if="isAuthenticated">
          <b-icon
            icon="person-fill"
            class="mr-2"
            style="color: black"
            aria-hidden="true"
          ></b-icon>
          <span style="color: black">{{ user.username }}</span>
        </v-btn>
      </div>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" absolute left temporary>
      <v-list nav dense>
        <template v-for="item in selected_menu">
          <v-list-item
            class="hidden-lg-and-up"
            :to="item.link"
            :key="item.title"
            v-if="!item.sub_items"
          >
            <v-list-item-title>
              <b-icon :icon="item.icon" aria-hidden="true"></b-icon>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>

          <v-list-group
            active-class="deep-purple--text text--accent-4"
            class="hidden-lg-and-up mobile_menu"
            v-if="item.sub_items"
            :key="item.title"
          >
            <template v-slot:activator>
              <v-list-item class="custom_navbar">
                <v-list-item-content>
                  <v-list-item-title>
                    <b-icon
                      class="mr-2"
                      :icon="item.icon"
                      aria-hidden="true"
                    ></b-icon>
                    <template v-if="isShowBadgeTopNav(item, globalState)">
                      <v-badge color="pink" inline dot>
                        {{ item.title }}
                      </v-badge>
                    </template>
                    <template v-else>
                      {{ item.title }}
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              v-for="sub_item in item.sub_items"
              :key="sub_item.title"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="sub_item.link">
                    <template v-if="isShowBadgeSubNav(sub_item, globalState)">
                      <v-badge color="pink" inline dot>
                        {{ sub_item.title }}
                      </v-badge>
                    </template>
                    <template v-else>{{ sub_item.title }}</template>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <v-list-item @click="logout">
          <v-list-item-title>
            <b-icon icon="door-open" aria-hidden="true"></b-icon>
            {{ $t("navbar.Logout") }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-dialog
      name="modal-manual"
      :width="`90%`"
      :height="`auto`"
      scrollable
      v-if="showPdfFileModal"
      v-model="showEdit"
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <div class="d-flex justify-content-start align-center">
              <span class="text-h5 mr-3">Manual</span>
            </div>
          </v-card-title>
          <v-divider class="mx-4 my-0"></v-divider>

          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p>PDF Description: {{ pdfItem.description }}</p>
                <div
                  class="pdf_contents"
                  style="height: 80vh"
                  v-if="pdfItem.pdfPath"
                >
                  <embed
                    :src="pdfItem.pdfFileUri"
                    width="100%"
                    height="100%"
                    type="application/pdf"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import _ from "lodash";

export default {
  name: "Navbar",
  components: {},
  data() {
    return {
      myImage: require("@/assets/logo.png"),
      locale: this.$i18n.locale || "es",
      drawer: false,
      group: null,
      manual: null,
      selected_menu: [],
      hideNavBar: false,
      pdfItem: {},
      showPdfFileModal: false,
      showEdit: false,
      pdfFilePrefix:
        process.env.VUE_APP_ENV !== "development"
          ? "https://docs.google.com/gview?embedded=true&url="
          : // ? "https://docs.google.com/viewerng/viewer?url="
            ""
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
    $route(to, from) {
      if (to && to?.name && to.name === "admin_survey_editor") {
        console.log("this is survey editor page !");
        this.hideNavBar = true;
      } else {
        this.hideNavBar = false;
      }
    }
  },
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isAdmin",
      "isSubAdmin",
      "isStaff",
      "isBrand",
      "isManager",
      "isSPV",
      "isGPV",
      "user",
      "globalState"
    ]),

    nav_users_item() {
      return {
        title: this.$t("navbar.user_admin"),
        link: "/salesforce/useradmin"
      };
    },
    nav_leads_item() {
      return {
        title: "Leads",
        link: "/salesforce/leads"
      };
    },
    nav_expenses_item() {
      return {
        title: this.$t("navbar.expenses"),
        link: "/expenses",
        key: "expenses"
      };
    },
    nav_calendarholidays_item() {
      return {
        title: this.$t("navbar.calendar_holidays"),
        link: "/holidaystaticdays"
      };
    },
    nav_holiday_item() {
      return {
        title: this.$t("holiday_and_leaves"),
        link: "/holidayleaves",
        key: "holidayleaves"
      };
    },
    nav_workday_item() {
      return {
        title: this.$t("Workday"),
        link: "/workday"
      };
    },
    nav_objectives() {
      return {
        title: this.$t("navbar.objectives"),
        link: "/salesforce/objectives"
      };
    },
    nav_controlkms() {
      return {
        title: this.$t("Control Kilometers"),
        link: "/salesforce/controlkms"
      };
    },
    nav_portalphotos() {
      return {
        title: `Portal Fotos`,
        link: "/salesforce/portalphotos"
      };
    },
    nav_brandreports() {
      return {
        title: `Informes Marca`,
        link: "/reporting/brand-reports"
      };
    },

    nav_routegpv() {
      return {
        title: `Rutero GPV`,
        link: "/reporting/routegpv"
      };
    },
    nav_sanciones() {
      return {
        title: `Sanciones`,
        link: "/salesforce/sanciones"
      };
    },
    nav_domains() {
      return {
        title: `Dominio`,
        link: "/salesforce/domains"
      };
    },
    nav_salesforce_admin() {
      let sub_items = [
        this.nav_users_item,
        this.nav_leads_item,
        this.nav_expenses_item,
        this.nav_calendarholidays_item,
        this.nav_holiday_item,
        this.nav_workday_item,
        this.nav_objectives,
        this.nav_controlkms,
        this.nav_sanciones
      ];
      if (this.isAdmin) sub_items = [...sub_items, this.nav_domains];
      return {
        icon: "box-arrow-down",
        title: this.$t("navbar.employee_portal"),
        link: "#",
        key: "employee_portal",
        sub_items
      };
    },
    nav_salesforce_manager() {
      return {
        icon: "box-arrow-down",
        title: this.$t("navbar.employee_portal"),
        link: "#",
        key: "employee_portal",
        sub_items: [
          this.nav_users_item,
          this.nav_expenses_item,
          this.nav_holiday_item,
          this.nav_workday_item,
          this.nav_objectives,
          this.nav_controlkms,
          this.nav_sanciones
        ]
      };
    },
    nav_salesforce_notadmin() {
      let sub_items = [
        this.nav_expenses_item,
        this.nav_holiday_item,
        this.nav_workday_item,
        this.nav_objectives,
        this.nav_controlkms
      ];
      if (!this.isGPV) sub_items = [...sub_items, this.nav_sanciones];
      return {
        icon: "box-arrow-down",
        title: this.$t("navbar.employee_portal"),
        link: "#",
        key: "employee_portal",
        sub_items
      };
    },
    nav_pos_change_route() {
      return {
        title: `${this.$t("Change Route")}`,
        link: "/pos/changeroute",
        key: "changeroute"
      };
    },
    nav_pos_inactive() {
      return {
        title: `POS ${this.$t("Inactive")}`,
        link: "/pos/inactive",
        key: "inactivepos"
      };
    },
    nav_pos_changegeolocation() {
      return {
        title: `Cambio GPS`,
        link: "/pos/changegeolocation",
        key: "changegeolocation"
      };
    },
    nav_pos_visitdays() {
      return {
        title: `${this.$t("Change Visit Day")}`,
        link: "/pos/visitdays",
        key: "visitdays"
      };
    },
    nav_pos_newpos() {
      return {
        title: `${this.$t("newitem")} POS`,
        link: "/pos/newpos",
        key: "newpos"
      };
    },
    nav_commercial_guidance() {
      return {
        title: `${this.$t("navbar.commercial_guidance")}`,
        link: "/guidance",
        key: "guidance"
      };
    },
    nav_manual() {
      return {
        title: "Manual",
        link: "/brands/manual",
        key: "manual"
      };
    },
    nav_myroute() {
      return {
        icon: "map",
        title: this.$t("navbar.MyRoute"),
        link: "/session"
      };
    },
    nav2_brand() {
      return {
        icon: "stickies-fill",
        title: this.$t("navbar.Brands"),
        link: "#",
        sub_items: [
          {
            title: this.$t("navbar.Brands"),
            link: "/config/brands"
          },
          {
            title: this.$t("brands.families"),
            link: "/brands/families"
          },
          {
            title: this.$t("navbar.products"),
            link: "/brands/products"
          },
          {
            title: this.$t("Material"),
            link: "/brands/material"
          },
          {
            title: `Surtido de materiales`,
            link: "/brands/material-assortment"
          },
          {
            title: "Tarifas Productos",
            link: "/brands/product-tariffs"
          },
          {
            title: this.$t("navbar.assortment"),
            link: "/brands/assortment"
          },
          {
            title: `${this.$t("brands.Sales")} Import`,
            link: "/brands/sales"
          },
          {
            title: `${this.$t("Question")} Import`,
            link: "/brands/questions"
          },
          {
            title: `Pedido Import`,
            link: "/brands/importsales"
          },
          {
            title: `Informes Marca`,
            link: "/brands/brand-reports"
          }
        ]
      };
    },
    nav2_comercial() {
      let subItems = [
        {
          title: this.$t("navbar.Surveys"),
          link: "/surveys"
        },
        {
          title: this.$t("navbar.objectives"),
          link: "/brands/objectives"
        },
        {
          title: this.$t("brands.Actions"),
          link: "/brands/actions"
        },
        {
          title: this.$t("Promos"),
          link: "/brands/promos"
        },
        {
          title: this.$t("onepage"),
          link: "/brands/onepage"
        },
        {
          title: "Fotos Anteriores",
          link: "/brands/anteriores"
        },
        {
          title: this.$t("postags"),
          link: "/pos/tags"
        }
      ];
      let otherSubItems = [this.nav_commercial_guidance];
      let sub_items = [];
      if (this.isAdmin) {
        sub_items = [
          ...subItems.slice(0, 6),
          ...otherSubItems,
          ...subItems.slice(6)
        ];
      } else {
        sub_items = otherSubItems;
      }
      if (!this.isBrand && !this.isGPV && !this.isSPV) {
        sub_items.push(this.nav_manual);
      }
      return {
        icon: "gear",
        title: this.$t("commercial"),
        link: "#",
        sub_items: sub_items
      };
    },
    task() {
      return {
        icon: "calendar3",
        title: "Mis Tareas",
        link: "#",
        key: "tasks",
        sub_items: [
          {
            title: "Vision Calendario",
            link: "/tasks/calendar"
          },
          {
            title: "Vision Proyectos Global",
            link: "/tasks/globalprojects"
          },
          {
            title: "Vision Proyecto Individual",
            link: "/tasks/individualproject"
          }
        ]
      };
    },
    nav2_pos() {
      return {
        icon: "geo-alt-fill",
        title: "POS",
        link: "#",
        key: "nav2_pos",
        sub_items: [
          {
            title: this.$t("chain") + " & " + this.$t("channel"),
            link: "/pos/chains"
          },
          {
            title: this.$t("zone"),
            link: "/pos/zone"
          },
          {
            title: this.$t("pos") + " " + this.$t("admin"),
            link: "/pos/admin"
          },
          {
            title: `ID ${this.$t("pos")} ${this.$t("brands.brand")}`,
            link: "/pos/posbrands"
          },
          {
            title: "Codigos POS",
            link: "/pos/poscodes"
          }
        ]
      };
    },
    nav2_reingenieria() {
      let subItems = [
        {
          title: this.$t("route") + " " + this.$t("admin"),
          link: "/pos/route"
        },
        {
          title: this.$t("pos") + " " + this.$t("route"),
          link: "/pos/posroutes"
        },
        {
          title: `${this.$t("pos")} ${this.$t("brands.brand")}`,
          link: "/pos/posformroutes"
        }
      ];
      let otherSubItems = [
        this.nav_pos_change_route,
        this.nav_pos_inactive,
        this.nav_pos_changegeolocation,
        this.nav_pos_newpos,
        this.nav_pos_visitdays
      ];
      let sub_items = [];
      if (this.isAdmin) {
        sub_items = [
          ...subItems.slice(0, 3),
          ...otherSubItems,
          ...subItems.slice(3)
        ];
      } else {
        sub_items = otherSubItems;
      }
      return {
        icon: "box",
        title: `Reingenieria`,
        link: "#",
        key: "nav_pos",
        sub_items: sub_items
      };
    },
    nav_reporting() {
      let subItems = [
        {
          title: `Report Jornada`,
          link: "/pos/actionlogs"
        },
        {
          title: `${this.$t("navbar.route_compliance")}`,
          link: "/reporting/routecompliance"
        },
        {
          title: `Punt PS`,
          link: "/reporting/puntps"
        },
        {
          title: `${this.$t("Answers")}`,
          link: "/reporting/answers"
        },
        {
          title: `Info mal recogida`,
          link: "/reporting/corrections"
        },
        {
          title: `${this.$t("brands.Sales")}`,
          link: "/reporting/sales"
        },
        {
          title: `${this.$t("navbar.salesexports")}`,
          link: "/reporting/sales-exports"
        },
        {
          title: `Apuntes Ult. Visita`,
          link: "/reporting/messagebrand"
        },
        {
          title: `${this.$t("Incidence")} ${this.$t("Messaging")}`,
          link: "/reporting/messageincidence"
        },
        {
          title: `Report Columna`,
          link: "/reporting/columna"
        },
        {
          title: `Ventas Pedido`,
          link: "/reporting/salespedidoemail"
        },
        {
          title: `POS Fotos`,
          link: "/reporting/posfotos"
        }
      ];
      let otherSubItems = [this.nav_portalphotos, this.nav_brandreports];
      let sub_items = [];
      if (this.isAdmin || this.isSubAdmin) {
        sub_items = [
          ...subItems.slice(0, 9),
          ...otherSubItems,
          ...subItems.slice(9)
        ];
      } else {
        sub_items = otherSubItems;
      }
      if (!this.isBrand && !this.isGPV) {
        sub_items.push(this.nav_routegpv);
      }
      return {
        icon: "flag",
        title: this.$t("reporting"),
        link: "#",
        key: "nav_reporting",
        sub_items: sub_items
      };
    },
    nav_cuadros_de_mando() {
      return {
        icon: "geo-alt-fill",
        title: "Cuadros de mando",
        link: "#",
        key: "nav_cuadros_de_mando",
        sub_items: [
          {
            title: `CDM Interno`,
            link: "/dashboard/cdminterno"
          }
        ]
      };
    },
    admin_menu() {
      return [
        this.nav2_brand,
        this.nav2_comercial,
        this.nav2_pos,
        this.nav2_reingenieria,
        this.nav_reporting,
        this.nav_cuadros_de_mando,
        this.nav_salesforce_admin,
        this.nav_myroute,
        this.task
      ];
    },
    subadmin_menu() {
      return [
        this.nav2_comercial,
        this.nav2_reingenieria,
        this.nav_reporting,
        this.nav_cuadros_de_mando,
        this.nav_salesforce_admin,
        this.nav_myroute,
        this.task
      ];
    },
    staff_menu() {
      return [
        this.nav2_comercial,
        this.nav_reporting,
        this.nav_salesforce_notadmin,
        this.nav_myroute,
        this.task
      ];
    },
    manager_menu() {
      return [
        this.nav2_comercial,
        this.nav2_reingenieria,
        this.nav_reporting,
        this.nav_salesforce_manager,
        this.nav_myroute,
        this.task
      ];
    },
    spv_menu() {
      return [
        this.nav2_comercial,
        this.nav2_reingenieria,
        this.nav_reporting,
        this.nav_salesforce_notadmin,
        this.nav_myroute,
        this.task
      ];
    },
    brand_menu() {
      return [this.nav_reporting, this.nav_myroute];
    },
    gpv_menu() {
      return [
        this.nav_reporting,
        this.nav_salesforce_notadmin,
        this.nav_myroute
      ];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    getImageUri() {
      const imgStatic = this.user?.company?.domain?.static;
      if (imgStatic && imgStatic?.type === "image") {
        return ApiService.getImageUri(imgStatic);
      }
      return null;
    },
    isShowBadgeTopNav(item, globalState) {
      return (
        (item.key &&
          item.key === "employee_portal" &&
          globalState?.notify_global_nav > 0) ||
        (item.key &&
          item.key === "nav_pos" &&
          globalState?.pending_inactivepos > 0) ||
        (item.key &&
          item.key === "nav_pos" &&
          globalState?.pending_request_visitdays > 0) ||
        (item.key &&
          item.key === "nav_pos" &&
          globalState?.pending_change_route > 0) ||
        (item.key &&
          item.key === "nav_pos" &&
          globalState?.pending_new_pos > 0) ||
        (item.key &&
          item.key === "nav_pos" &&
          globalState?.pending_pos_change_approval > 0) ||
        (item.key && item.key === "tasks")
      );
    },
    isShowBadgeSubNav(sub_item, globalState) {
      return (
        (sub_item.key &&
          sub_item.key === "expenses" &&
          globalState?.notify_navs.expenses > 0) ||
        (sub_item.key &&
          sub_item.key === "holidayleaves" &&
          globalState?.notify_navs.holidayleaves > 0) ||
        (sub_item.key &&
          sub_item.key === "inactivepos" &&
          globalState?.pending_inactivepos > 0) ||
        (sub_item.key &&
          sub_item.key === "visitdays" &&
          globalState?.pending_request_visitdays > 0) ||
        (sub_item.key &&
          sub_item.key === "changeroute" &&
          globalState?.pending_change_route > 0) ||
        (sub_item.key &&
          sub_item.key === "newpos" &&
          globalState?.pending_new_pos > 0) ||
        (sub_item.key &&
          sub_item.key === "changegeolocation" &&
          globalState?.pending_pos_change_approval > 0)
      );
    },
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.locale = locale;
        if (this.$store.state.auth.user) {
          this.setMenuItems();
        }
      }
    },
    showDialogPdfFile() {
      this.showPdfFileModal = true;
      this.showEdit = true;
    },
    hideModal() {
      this.showEdit = false;
      this.showPdfFileModal = false;
      this.$nextTick(() => {});
    },
    setMenuItems() {
      if (this.$store.state.auth.user) {
        switch (this.$store.state.auth.user.role) {
          case "admin":
            this.selected_menu = this.admin_menu;
            break;
          case "subadmin":
            this.selected_menu = this.subadmin_menu;
            break;
          case "staff":
            this.selected_menu = this.staff_menu;
            break;
          case "brand":
            this.selected_menu = this.brand_menu;
            break;
          case "manager":
            this.selected_menu = this.manager_menu;
            break;
          case "spv":
            this.selected_menu = this.spv_menu;
            break;
          case "gpv":
            this.selected_menu = this.gpv_menu;
            break;
          default:
            this.selected_menu = this.gpv_menu;
            break;
        }
      }
    },
    async init() {
      try {
        let fetch_url = "brands/manual";
        const resp = await ApiService.get(fetch_url);
        this.manual = resp?.data?.[0];
        const pdfFileUri = ApiService.getFileUri(this.manual?.static);
        this.pdfItem["description"] = this.manual?.description;
        this.pdfItem["pdfFileUri"] = pdfFileUri;
        this.pdfItem["pdfPath"] = this.pdfFilePrefix + pdfFileUri;
        console.log("------------- pdfFile ---------------", this.pdfItem);
      } catch (error) {}
    }
  },
  updated() {
    this.setMenuItems();
  },
  async mounted() {
    this.init();
  }
};
</script>

<style scoped>
.router-link-exact-active {
  color: black;
  font-weight: bold;
}

a {
  color: #888;
}

.logo-title {
  color: black;
  font-size: 11px;
  font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: red;
}

.navbar {
  font-size: small;
  padding: 0px;
  padding-left: 5px;
  border-bottom: 1px solid #ddd;
}

.logo-version {
  font-size: 11px;
  font-weight: bold;
  color: #34b944;
}

.logo-image {
  background-position: left;
}

.custom-flag {
  margin-bottom: 0 !important;
}

.v-btn {
  text-transform: capitalize;
}
.btn_home {
  text-decoration: none;
}
</style>
