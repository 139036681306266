import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const requireAuth = adminLevel => {
  return function(to, from, next) {
    store
      .dispatch("auth/check")
      .then(user => {
        if (adminLevel && user.role !== "admin") {
          return next({
            path: "/"
          });
        }
        // if (user.role == "brand") {
        //   return next({
        //     path: "/salesforce/portalphotos"
        //   });
        // }
        next();
      })
      .catch(() =>
        next({
          path: "/auth/login"
        })
      );
  };
};

const requireAuthorization = auth => {
  return function(to, from, next) {
    store
      .dispatch("auth/check")
      .then(user => {
        if (user.role === "brand") {
          console.log("is brand !");
          next();
        } else {
          if (!auth.includes(user.role)) {
            return next({
              path: "/"
            });
          }
          next();
        }
      })
      .catch(() =>
        next({
          path: "/auth/login"
        })
      );
  };
};

const routes = [
  /****** Auth Routes ******/
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/auth"),
    children: [
      { path: "", redirect: "login" },
      {
        path: "login",
        name: "login",
        component: () => import("@/views/auth/Login")
      },
      {
        path: "forgotpassword",
        name: "forgotpassword",
        component: () => import("@/views/auth/forgotpassword")
      },
      {
        path: "resetpassword/:token/:email",
        component: () => import("@/views/auth/resetpassword"),
        props: true
      }
    ]
  },

  // {
  //   path: "/",
  //   name: "home",
  //   redirect: 'session',
  //   beforeEnter: requireAuth(false),
  //   component: () => import("@/views/config")
  // },
  {
    path: "/",
    name: "home",
    redirect: "session",
    beforeEnter: requireAuth(false),
    component: () => import("@/views/config")
  },
  {
    path: "/memoryexceed",
    name: "router_memoryexceed_page",
    beforeEnter: requireAuth(false),
    component: () => import("@/views/common/memoryexceed")
  },

  /****** Session Routes ******/
  {
    path: "/session",
    name: "session",
    beforeEnter: requireAuth(false),
    component: () => import("@/views/session"),
    children: [
      // Note we provide the above parent route name on the default child tab
      // route to ensure this tab is rendered by default when using named routes
      { path: "", redirect: "dashboard" },
      {
        path: "offline",
        name: "router_offline_page",
        component: () => import("@/views/session/offline")
      },
      {
        path: "dashboard",
        name: "router_worksession_dashboard",
        component: () => import("@/views/session/dashboard")
      },
      {
        path: "newpos/:worksessionId",
        component: () => import("@/views/session/newpos"),
        props: true
      },
      {
        path: ":id/route",
        name: "router_worksessionroute",
        component: () => import("@/views/session/route")
      },
      {
        path: ":id/pos/:worksessionPosId",
        name: "router_worksessionpos",
        component: () => import("@/views/session/worksessionpos")
      },
      {
        path: ":id/pospage/:worksessionPosId/:frompage",
        name: "router_worksessionpospage",
        component: () => import("@/views/session/worksessionpos")
      },
      {
        path: ":id/pospage/:worksessionPosId/:frompage/:brandId",
        name: "router_worksessionpospage",
        component: () => import("@/views/session/worksessionpos")
      },
      {
        path: ":id/pos/:worksessionPosId/:brandId",
        name: "router_worksessionpos_brand",
        component: () => import("@/views/session/worksessionpos"),
        props: true
      },
      {
        path: ":id/pos/:worksessionPosId/survey/:brandId",
        name: "router_worksessionpos_survey",
        component: () => import("@/views/session/posbrandsurvey"),
        props: true
      },
      {
        path: ":id/pos/:worksessionPosId/surveyv2/:brandId",
        name: "router_worksessionpos_surveyv2",
        component: () => import("@/views/session/posbrandsurvey/indexv2"),
        props: true
      },
      {
        path: ":id/schedule/:worksessionPosId",
        name: "router_worksessionschedulepos",
        component: () => import("@/views/session/schedulepos")
      },
      {
        path: ":id/editpos/:worksessionPosId/:frompage",
        name: "router_editposdata",
        component: () => import("@/views/session/posdata"),
        children: [
          { path: "", redirect: "edit" },
          {
            path: "edit",
            component: () => import("@/views/session/posdata/editpos"),
            name: "router_editposdata_edit",
            props: true
          },
          {
            path: "geolocation",
            component: () => import("@/views/session/posdata/geolocation"),
            name: "router_editposdata_geolocation",
            props: true
          },
          {
            path: "visitday",
            component: () => import("@/views/session/posdata/visitday"),
            name: "router_editposdata_visitday",
            props: true
          },
          {
            path: "inactive",
            component: () => import("@/views/session/posdata/inactive"),
            name: "router_editposdata_inactive",
            props: true
          },
          {
            path: "changeroute",
            component: () => import("@/views/session/posdata/changeroute"),
            name: "router_editposdata_changeroute",
            props: true
          }
        ]
      },
      {
        path: ":id/sales/:worksessionPosId/:worksessionPosBrandId",
        name: "router_worksessionsales",
        component: () => import("@/views/session/sales"),
        props: true
      },
      {
        path: ":id/endvisit/:worksessionPosId",
        name: "router_worksessionendvisit",
        component: () => import("@/views/session/endvisit")
      },
      {
        path: ":id/pos/:posId/survey/:surveyId",
        component: () => import("@/views/session/survey/index")
      },
      {
        path: ":id/dailysummary/route",
        name: "router_worksession_dailysummarybyroute",
        component: () =>
          import("@/views/session/finishroute/DailySummaryByRoute"),
        props: true
      },
      {
        path: ":id/dailysummary/pos",
        name: "router_worksession_dailysummarybypos",
        component: () =>
          import("@/views/session/finishroute/DailySummaryByPOS"),
        props: true
      }
    ]
  },

  /****** Config Routes ******/
  {
    path: "/config",
    // We don't provide a name on this parent route, but rather
    // set the name on the default child route instead
    name: "config",
    beforeEnter: requireAuth(true),
    component: () => import("@/views/config"),
    children: [
      // Note we provide the above parent route name on the default child tab
      // route to ensure this tab is rendered by default when using named routes
      { path: "", redirect: "surveys" },
      {
        path: "surveys",
        component: () => import("@/views/config/surveys"),
        children: [
          { path: "", component: () => import("@/views/config/surveys/list") },
          {
            path: "editor/:id",
            component: () => import("@/views/config/surveys/editor")
          }
        ]
      },
      { path: "brands", component: () => import("@/views/config/brands") }
    ]
  },

  /****** Surveys Routes ******/
  {
    path: "/surveys",
    // We don't provide a name on this parent route, but rather
    // set the name on the default child route instead
    name: "surveys",
    beforeEnter: requireAuth(true),
    component: () => import("@/views/surveys"),
    children: [
      // Note we provide the above parent route name on the default child tab
      // route to ensure this tab is rendered by default when using named routes
      { path: "", redirect: "list" },
      { path: "list", component: () => import("@/views/surveys/list") },
      {
        path: "editor/:id",
        name: "admin_survey_editor",
        component: () => import("@/views/surveys/editor")
      }
    ]
  },

  /****** Cuadros de mando - Dashboard ******/
  {
    path: "/dashboard",
    // We don't provide a name on this parent route, but rather
    // set the name on the default child route instead
    name: "admin_dashboard",
    beforeEnter: requireAuthorization(["admin", "subadmin"]),
    component: () => import("@/views/dashboard"),
    children: [
      // Note we provide the above parent route name on the default child tab
      // route to ensure this tab is rendered by default when using named routes
      { path: "", redirect: "cdminterno" },
      {
        path: "cdminterno",
        component: () => import("@/views/dashboard/cdminterno")
      }
    ]
  },

  /****** salesforce Routes ******/
  {
    path: "/salesforce",
    name: "salesforce",
    beforeEnter: requireAuthorization([
      "admin",
      "subadmin",
      "spv",
      "gpv",
      "staff",
      "brand",
      "manager"
    ]),
    component: () => import("@/views/salesforce"),
    children: [
      { path: "", redirect: "useradmin" },
      {
        path: "objectives",
        component: () => import("@/views/salesforce/objectivos")
      },
      {
        path: "controlkms",
        component: () => import("@/views/salesforce/controlkms")
      },
      {
        path: "portalphotos",
        component: () => import("@/views/salesforce/portalphotos")
      },
      {
        path: "useradmin",
        beforeEnter: requireAuthorization(["admin", "subadmin", "manager"]),
        component: () => import("@/views/salesforce/users")
      },
      {
        path: "leads",
        beforeEnter: requireAuthorization(["admin", "subadmin", "manager"]),
        component: () => import("@/views/salesforce/leads")
      },
      {
        path: "domains",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/salesforce/domains")
      },
      {
        path: "sanciones",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "staff",
          "manager"
        ]),
        component: () => import("@/views/salesforce/sanciones")
      }
    ]
  },

  /****** brands Routes ******/
  {
    path: "/brands",
    name: "brands",
    beforeEnter: requireAuth(true),
    component: () => import("@/views/brands"),
    children: [
      { path: "", redirect: "products" },
      { path: "products", component: () => import("@/views/brands/products") },
      { path: "material", component: () => import("@/views/brands/material") },
      {
        path: "material-assortment",
        component: () => import("@/views/brands/materialassortment")
      },
      {
        path: "assortment",
        component: () => import("@/views/brands/assortment")
      },
      { path: "families", component: () => import("@/views/brands/families") },
      {
        path: "product-tariffs",
        component: () => import("@/views/brands/producttariffs")
      },
      {
        path: "objectives",
        component: () => import("@/views/brands/objectives")
      },
      { path: "onepage", component: () => import("@/views/brands/onepage") },
      {
        path: "anteriores",
        component: () => import("@/views/brands/anteriores")
      },
      { path: "actions", component: () => import("@/views/brands/actions") },
      { path: "sales", component: () => import("@/views/brands/sales") },
      { path: "promos", component: () => import("@/views/brands/promos") },
      {
        path: "questions",
        component: () => import("@/views/brands/questions")
      },
      {
        path: "importsales",
        component: () => import("@/views/brands/importsales")
      },
      {
        path: "brand-reports",
        component: () => import("@/views/brands/brandreports")
      },
      {
        path: "manual",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "manager"
        ]),
        component: () => import("@/views/brands/manual")
      }
    ]
  },

  /****** pos Routes ******/
  {
    path: "/pos",
    name: "pos",
    // beforeEnter: requireAuth(true),
    component: () => import("@/views/pos"),
    children: [
      { path: "", redirect: "chains" },
      {
        path: "chains",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/chains")
      },
      {
        path: "zone",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/zone")
      },
      {
        path: "admin",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/posadmin")
      },
      {
        path: "route",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/route")
      },
      {
        path: "posroutes",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/posroutes")
      },
      {
        path: "posformroutes",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/posformroutes")
      },
      {
        path: "tags",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/tags")
      },
      {
        path: "posbrands",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/posbrands")
      },
      {
        path: "poscodes",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/poscodes")
      },
      {
        path: "actionlogs",
        beforeEnter: requireAuth(true),
        component: () => import("@/views/pos/actionlogs")
      },
      {
        path: "changeroute",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "manager"
        ]),
        component: () => import("@/views/pos/changeroute")
      },
      {
        path: "inactive",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "manager"
        ]),
        component: () => import("@/views/pos/inactivepos")
      },
      {
        path: "changegeolocation",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "manager"
        ]),
        component: () => import("@/views/pos/changegeolocation")
      },
      {
        path: "visitdays",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "manager"
        ]),
        component: () => import("@/views/pos/visitdays")
      },
      {
        path: "newpos",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "manager"
        ]),
        component: () => import("@/views/pos/newpos")
      }
    ]
  },

  /****** expenses Routes ******/
  {
    path: "/expenses",
    name: "expenses",
    beforeEnter: requireAuthorization([
      "admin",
      "subadmin",
      "spv",
      "gpv",
      "staff",
      "manager"
    ]),
    component: () => import("@/views/expenses"),
    children: [
      { path: "", redirect: "approval/my" },
      {
        path: "approval/:isteam",
        component: () => import("@/views/expenses/approval"),
        props: true
      },
      {
        path: "liquidation/:isteam",
        component: () => import("@/views/expenses/liquidation"),
        props: true
      },
      {
        path: "liquidation/detail/:isteam/:year/:month/:userId",
        component: () => import("@/views/expenses/liquidation/monthdetail"),
        props: true
      },
      {
        path: "analysis",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "manager"
        ]),
        component: () => import("@/views/expenses/analysis")
      },
      {
        path: "solred",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/expenses/solred")
      }
    ]
  },
  {
    path: "/leads",
    beforeEnter: requireAuthorization([
      "admin",
      "subadmin",
      "spv",
      "gpv",
      "staff",
      "manager"
    ]),
    component: () => import("@/views/leads"),
    children: [
      { path: "", redirect: "informacion" },
      {
        path: "informacion",
        name: "leads",
        component: () => import("@/views/leads/informacion"),
        props: true
      },
      {
        path: "presupuestos",
        component: () => import("@/views/leads/presupuestos"),
        props: true
      },
      {
        path: "facturas",
        component: () => import("@/views/leads/facturas"),
        props: true
      },
      {
        path: "historico",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "manager"
        ]),
        component: () => import("@/views/leads/historico")
      },
      {
        path: "solred",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/expenses/solred")
      }
    ]
  },

  /****** holidayleaves Routes ******/
  {
    path: "/holidayleaves",
    name: "holidayleaves",
    beforeEnter: requireAuthorization([
      "admin",
      "subadmin",
      "spv",
      "gpv",
      "staff",
      "manager"
    ]),
    component: () => import("@/views/holidayleaves"),
    children: [
      { path: "", redirect: "holiday/my" },
      {
        path: "holiday/:isteam",
        component: () => import("@/views/holidayleaves/holiday"),
        props: true
      },
      {
        path: "leaves/:isteam",
        component: () => import("@/views/holidayleaves/leaves"),
        props: true
      },
      {
        path: "calendar",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "manager"
        ]),
        component: () => import("@/views/holidayleaves/calendar")
      }
    ]
  },

  /****** holidaystaticdays Routes ******/
  {
    path: "/holidaystaticdays",
    name: "holidaystaticdays",
    beforeEnter: requireAuthorization(["admin", "subadmin"]),
    component: () => import("@/views/holidayleaves/holidaystaticdays")
  },

  /****** workday Routes ******/
  {
    path: "/workday",
    name: "workday",
    beforeEnter: requireAuthorization([
      "admin",
      "subadmin",
      "spv",
      "gpv",
      "staff",
      "manager"
    ]),
    component: () => import("@/views/workday"),
    children: [
      { path: "", redirect: "calculator" },
      {
        path: "calculator",
        component: () => import("@/views/workday/calculator"),
        props: true
      },
      {
        path: "extract",
        component: () => import("@/views/workday/extract"),
        props: true
      }
    ]
  },

  /****** commercial guidance ******/
  {
    path: "/guidance",
    name: "guidance",
    beforeEnter: requireAuthorization([
      "admin",
      "subadmin",
      "staff",
      "brand",
      "manager",
      "spv",
      "gpv"
    ]),
    component: () => import("@/views/guidance")
  },

  /****** mis-tareas ******/
  {
    path: "/tasks",
    name: "tasks",
    beforeEnter: requireAuthorization([
      "admin",
      "subadmin",
      "spv",
      "staff",
      "manager"
    ]),
    component: () => import("@/views/tasks"),
    children: [
      { path: "", redirect: "calendar" },
      {
        path: "calendar",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "staff",
          "manager"
        ]),
        component: () => import("@/views/tasks/calendar")
      },
      {
        path: "globalprojects",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "staff",
          "manager"
        ]),
        component: () => import("@/views/tasks/globalprojects")
      },
      {
        path: "individualproject",
        name: "individual_project",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "staff",
          "manager"
        ]),
        component: () => import("@/views/tasks/individualproject")
      }
    ]
  },

  /****** reporting ******/
  {
    path: "/reporting",
    name: "reporting",
    // beforeEnter: requireAuthorization(["admin", "subadmin"]),
    component: () => import("@/views/reporting"),
    children: [
      { path: "", redirect: "routecompliance" },
      {
        path: "routecompliance",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/routecompliance")
      },
      {
        path: "puntps",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/puntps")
      },
      {
        path: "answers",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/answers")
      },
      {
        path: "corrections",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/corrections")
      },
      {
        path: "sales",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/sales")
      },
      {
        path: "messagebrand",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/guidance/MessageBrand")
      },
      {
        path: "sales-exports",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/salesexports")
      },
      {
        path: "brand-reports",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/brandreports")
      },
      {
        path: "messageincidence",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/guidance/MessageIncidence")
      },
      {
        path: "salespedidoemail",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/salespedidoemail")
      },
      {
        path: "routegpv",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "staff",
          "manager"
        ]),
        component: () => import("@/views/reporting/routegpv")
      },
      {
        path: "columna",
        beforeEnter: requireAuthorization(["admin", "subadmin"]),
        component: () => import("@/views/reporting/columna")
      },
      {
        path: "posfotos",
        beforeEnter: requireAuthorization([
          "admin",
          "subadmin",
          "spv",
          "staff",
          "manager"
        ]),
        component: () => import("@/views/reporting/posfotos")
      },
      {
        path: "posfotosdetail/:brandId/:posId/:surveyId",
        name: "pos_fotos_detail",
        component: () => import("@/views/reporting/posfotos/fotosDetail")
      }
    ]
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes
});

export default router;
